import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { YandexTranslatorService } from './yandex-translator/yandex-translator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('inputField', {static: false}) inputField;
  phrases: string[] = [];
  phrasesJson: any;
  translationsJson: any;
  title = 'cg-translations';
  activeLanguage: string;
  languages = [
    { name: 'en', active: false },
    { name: 'de', active: false },
    { name: 'ru', active: false }
  ];

  constructor(
    private yandex: YandexTranslatorService
  ) {}
  private prepareArrayFromInput(text: string): string[] {
    const array = text.split('\n');
    return array;
  }
  prepearePhrases(event: any) {
    this.phrases = this.prepareArrayFromInput(event.target.value);
    this.phrasesJson = this.buildJsonformPhrases(this.phrases);
  }
  prepearePhrasesFromPaste(event: any) {
    this.phrases = event.clipboardData.getData();
    this.phrasesJson = this.buildJsonformPhrases(this.phrases);
  }
  buildJsonformPhrases(phrases: string[]): any {
    const phrasesJson = new Object();
    phrases.forEach((phrase)  => {
      phrasesJson[phrase] = phrase;
    });
    return phrasesJson;
  }
  translateTo(language: any): void {
    this.activeLanguage = language.name;
    this.languages.forEach((currentLanguage: any) => {
      currentLanguage.active = false;
    });
    language.active = true;
    this.yandex.translate(this.phrases, this.activeLanguage).subscribe((data: any) => {
      this.translationsJson = this.translationsToOutput(data.text);
    });
  }
  translationsToOutput(translations: string[], phrases = this.phrases) {
    const object = new Object();
    translations.forEach((text, index) => {
      object[phrases[index]] = text;
    });
    return object;
  }
  ngAfterViewInit() {
    console.log(this.inputField.nativeElement)
    setTimeout(_ => {
      this.inputField.nativeElement.focus();
    }, 50);
  }
}
