import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class YandexTranslatorService {
  key = 'trnsl.1.1.20181130T075759Z.04bf7aafc9378376.dcd7c02f03d93ea5d6390e3ce568345577f0e3cd';
  apiUrl = 'https://translate.yandex.net/api/v1.5/tr.json/translate';
  constructor(private http: HttpClient) { }
  public translate(texts: string | string[], lang: string) {
    let phrase = '';
    if (typeof texts === 'string') {
      phrase += '&text=' + texts;
    } else {
      texts.forEach(text => {
        phrase += '&text=' + text;
      });
    }
    return this.http.get(this.apiUrl + '?key=' +  this.key + '&lang=pl-' + lang + '' + phrase);
  }
}
